(function () {
    'use strict';

    angular
        .module('staffprofile.bimiboo.com')
        .directive('dropzone', dropzone);

    function dropzone() {
        return {
            restrict: 'C',
            link: function(scope, element, attrs) {

                var config = {
                    url: 'api/uploads?attachable_type=' + scope.attachableType + '&attachable_id=' + scope.attachableId,
                    maxFilesize: 100,
                    paramName: "uploadfile",
                    maxThumbnailFilesize: 10,
                    parallelUploads: 1,
                    autoProcessQueue: false
                };

                config.headers = config.headers || {};
                if (localStorage.getItem('token')) {
                    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
                }

                var eventHandlers = {
                    'addedfile': function(file) {
                        scope.file = file;
                        if (this.files[1] != null) {
                            this.removeFile(this.files[0]);
                        }
                        scope.$apply(function() {
                            scope.fileAdded = true;
                        });
                    },

                    'success': function (file, response) {
                        scope.fileUploaded(file, response);
                    }

                };

                dropzone = new Dropzone(element[0], config);

                dropzone.on("processing", function(file) {
                    this.options.url = 'api/uploads?attachable_type=' + scope.attachableType + '&attachable_id=' + scope.attachableId;
                });

                angular.forEach(eventHandlers, function(handler, event) {
                    dropzone.on(event, handler);
                });

                scope.processDropzone = function() {
                    dropzone.processQueue();
                };

                scope.resetDropzone = function() {
                    dropzone.removeAllFiles();
                }
            }
        }
    }

})();