(function () {
    'use strict';
    
    
    var env = {};
    if(window){  
        Object.assign(env, window._env);
    }
    
    angular
        .module('staffprofile.bimiboo.com', [
            'ui.router', 'ngSanitize', 'templates', 'bw.paging', 'schemaForm', 'ui.ace', 'treeControl', 'ui.sortable'
        ])
        .constant('_env', env)
        .config(httpProviderConfig)
        .config(routeProviderConfig)
        .run(run);
    
    function httpProviderConfig($httpProvider) {
        $httpProvider.interceptors.push(function($q) {
            return {
                'responseError': function(rejection) {
                    var defer = $q.defer();
                    if(rejection.status == 401) {
                        window.location.assign('#/login');
                    }
                    defer.reject(rejection);

                    return defer.promise;
                },
                'request': function(config) {
                    config.headers = config.headers || {};
                    if (localStorage.getItem('token')) {
                        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
                    }
                    return config || $q.when(config);
                }
            };
        });
    }

    function routeProviderConfig($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.when('/', '/fill').otherwise('/fill');
        $stateProvider
            .state('fill', {
                url: '/fill',
                templateUrl: 'templates/fill-profile/view.html',
                controller: 'FillProfileCtrl',
                restrictions: {
                    ensureAuthenticated: false
                }
            });

    }

    function run($rootScope, $timeout) {

        $rootScope.formatDate = formatDate;

        $rootScope.$on('$stateChangeSuccess', function () {
            $timeout(function () {
                // fix sidebar
                var neg = $('.main-header').outerHeight() + $('.main-footer').outerHeight()
                var window_height = $(window).height()
                var sidebar_height = $('.sidebar').height()

                if ($('body').hasClass('fixed')) {
                    $('.content-wrapper, .right-side').css('min-height', window_height - $('.main-footer').outerHeight())
                } else {
                    if (window_height >= sidebar_height) {
                        $('.content-wrapper, .right-side').css('min-height', window_height - neg)
                    } else {
                        $('.content-wrapper, .right-side').css('min-height', sidebar_height)
                    }
                }
            })
        })
    }
    
})();
