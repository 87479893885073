(function () {
    'use strict';

    angular
        .module('staffprofile.bimiboo.com')
        .directive('simpleFilter', simpleFilter);

    function simpleFilter() {
        return {
            restrict: 'A',
            scope: {
                config: "="
            },
            replace: true,
            templateUrl: 'templates/directives/simple-filter.html',
            link: function (scope, element) {
                if (!scope.config) {
                    scope.config = {};
                }

                scope.searchableEnabled = scope.config.searchableEnabled !== undefined ? scope.config.searchableEnabled : true;
                scope.selectAllEnabled = scope.config.selectAllEnabled !== undefined ? scope.config.selectAllEnabled : true;
                scope.items = scope.config.items;
                scope.value = 'All';
                scope.allSelected = true;
                scope.visible = false;
                scope.filtered = false;

                $(document).bind('click', function(event){
                    var isClickedElementChildOfPopup = element
                        .find(event.target)
                        .length > 0;

                    if (isClickedElementChildOfPopup)
                        return;

                    scope.$apply(function() {
                        scope.visible = false;
                    });
                });

                function setValue() {
                    var selectedItems = _.map(scope.config.getSelectedItems(), function (item) {
                        return item.name;
                    });

                    scope.filtered = false;
                    if (selectedItems.length == 0 || selectedItems.length == scope.items.length) {
                        scope.value = 'All ' + scope.config.title;
                    } else if (selectedItems.length == 1) {
                        scope.value = selectedItems[0];
                        scope.filtered = true;
                    } else {
                        scope.value = scope.config.title + ' ' + selectedItems.length + '/' + scope.items.length;
                        scope.filtered = true;
                    }
                }

                scope.selectAll = function () {
                    scope.allSelected = !scope.allSelected;

                    _.forEach(scope.items, function (i) {
                        i.selected = scope.allSelected;
                    });

                    setValue();

                    scope.config.onChange();
                };

                scope.select = function (item) {
                    item.selected = !item.selected;

                    var allSelected = true;
                    _.forEach(scope.items, function (item) {
                        if (!item.selected) {
                            allSelected = false;
                        }
                    });
                    scope.allSelected = allSelected;

                    setValue();

                    scope.config.onChange();
                };

                scope.clear = function() {
                    _.forEach(scope.items, function (item) {
                        item.selected = false;
                    });
                    scope.allSelected = false;
                    setValue();

                    scope.config.onChange();
                }

                scope.toggle = function () {
                    scope.visible = !scope.visible;
                }

                scope.config.getSelectedItems = function () {
                    if (scope.allSelected) return [];

                    return _.filter(scope.items, function (item) {
                        return item.selected;
                    });
                };

                scope.config.getSelectedItemsAsString = function () {
                    var str = _.map(scope.config.getSelectedItems(), function (item) {
                        return item.id;
                    }).join(',');

                    return str ? str : '';
                };

                scope.config.setSelectedItemsFromString = function (str) {
                    if (!str) return;

                    var needParseToInt = scope.items && scope.items.length > 0 && typeof scope.items[0].id == 'number'

                    var selectedIds = _.map(str.split(','), function (splited) {
                        splited = splited.trim();
                        if (needParseToInt) {
                            return parseInt(splited);
                        }

                        return splited;
                    });

                    if (selectedIds.length > 0 && selectedIds.length < scope.items.length) {
                        scope.allSelected = false;
                        _.forEach(scope.items, function (item) {
                           item.selected = selectedIds.indexOf(item.id) > -1;
                        });

                        setValue();
                        scope.config.onChange();
                    }
                };

                setValue();
            }
        }
    }
})();