(function () {
    'use strict';

    angular
        .module('staffprofile.bimiboo.com')
        .factory('AuthenticationService', AuthenticationService)
        .factory('focus', function($timeout, $window) {
            return function(id) {
                $timeout(function() {
                    var element = $window.document.getElementById(id);
                    if(element)
                        element.focus();
                });
            };
        })
        .service('Uploader', Uploader);

    function AuthenticationService($http, _env) {
        var service = {};

        service.Login = Login;
        service.Logout = Logout;

        service.user = {}
        service.user.can = function (ability) {
            return service.user && service.user.permissions && service.user.permissions.indexOf(ability) != -1;
        }

        service.refresh = function(callback) {
            $http.get(_env.apiUrl + '/users/me').then(function(response) {
                angular.merge(service.user, response.data);

                $('[data-widget="tree"]').tree();

                if (callback) callback();
            }, function(err) {});
        }

        function Login(username, password, success, failure) {
            $http.post(_env.apiUrl + '/auth/login', { email: username, password: password })
                .then(function (response) {
                    localStorage.setItem('token', response.data.token);
                    success();
                }, function(response) {
                    failure(response);
                });
        }

        function Logout() {
            localStorage.removeItem('token');
            window.location.assign('#/login');
        }

        return service;
    }

    function Uploader($http) {
        this.uploadFilesToUrl = function(files, uploadUrl, callback) {
            var fd = new FormData();
            _.forEach(files, function (file) {
                if (!file) return;

                fd.append('file' + guid(), file);
                fd.append('name', file.name);
            });
            $http
                .post(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined,'Process-Data': false}
                })
                .success(function(data){
                    callback.success(data);
                })
                .error(function(error){
                    callback.error(error);
                });
        };

        this.uploadFilesToUrl = function(files, uploadUrl, relativePath, callback) {
            var fd = new FormData();
            _.forEach(files, function (file) {
                if (!file) return;
                fd.append('file' + guid(), file);
                fd.append('name', file.name);
            });
            fd.append('relativePath', relativePath);
            $http
                .post(uploadUrl, fd, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined,'Process-Data': false}
                })
                .success(function(data){
                    callback.success(data);
                })
                .error(function(error){
                    callback.error(error);
                });
        }
    }

})();